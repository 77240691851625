.form-new {
    display: flex;
    width:700px;
    flex-direction: column;
    gap: 10px;
    background-color: #fff;
    border-radius: 20px;
    padding: 30px 20px;
    box-shadow: 100px 100px 80px rgba(0, 0, 0, 0.03);
  }
  @media (max-width:800px) {
    .form-new {
      width:90%;
    }
  }
  .title-new {
    color: black;
    font-weight: bold;
    text-align: center;
    font-size: 20px;
    margin-bottom: 4px;
  }
  
  .sub {
    text-align: center;
    color: black;
    font-size: 14px;
    width: 100%;
  }
  
  .sub.mb {
    margin-bottom: 1px;
  }
  
  .sub a {
    color: rgb(23, 111, 211);
  }
  
  .avatar-new {
    height: 50px;
    width: 50px;
    background-color: rgb(23, 111, 211);
    border-radius: 50%;
    align-self: center;
    padding: 6px;
    cursor: pointer;
    box-shadow: 12.5px 12.5px 10px rgba(0, 0, 0, 0.015),100px 100px 80px rgba(0, 0, 0, 0.03);
  }
  
  .form-new .button-new {
    align-self: flex-end;
  }
  
  .input-new, .button-new {
    border: none;
    outline: none;
    width: 100%;
    padding: 16px 10px;
    background-color: rgb(247, 243, 243);
    border-radius: 10px;
    box-shadow: 12.5px 12.5px 10px rgba(0, 0, 0, 0.015),100px 100px 80px rgba(0, 0, 0, 0.03);
  }
  
  .button-new {
    margin-top: 12px;
    background-color: rgb(23, 111, 211);
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  .input-new:focus {
    border: 1px solid rgb(23, 111, 211);
  }
  
  #file-new {
    display: none;
  }