@media (max-width: 1400px) {
    .hero_area {
        background-size: cover;

    }
}

@media (max-width: 1200px) {
    .container-bg {
        border-radius: 0;
        background-color: transparent;
        position: relative;
    }

    .container-bg::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1120px;
        height: 100%;
        border-radius: 1500px;
        background-color: #fff;
        z-index: -1;
        border-radius: 100%;
    }
}

@media (max-width: 1120px) {}

@media (max-width: 992px) {
    .hero_area {
        height: auto;
        min-height: auto;
    }

    .slider_section .box {
        width: 100%;
        margin: 75px 0;
    }

    .slider_section .btn-box {
        margin-bottom: 90px;
    }

    .custom_nav-container .nav_search-btn {
        background-position: center;
    }

    .trip_section .box .form_container,
    .package_section .box .detail-container {
        width: 100%;
    }

    .blog_section .box {
        flex-direction: column;
    }

    .blog_section .container-bg::before {

        width: 1450px;
    }

    .blog_section .blog_box-cover {
        display: flex;
    }

    .info_section::before {
        bottom: 0;
        transform: translate(-50%, 0) scale(3.1);
    }

    .info_section .row>div {
        align-items: center;
        text-align: center;
    }

    .info_section .social_container {
        margin-bottom: 70px;
    }

    .info_section .info_link-box {

        display: flex;
        flex-direction: column;
        align-items: center;

    }
}

@media (max-width: 768px) {
    
    .blog_section .blog_box-cover {
        flex-direction: column;
    }

    .package_section .box .detail-container {
        flex-direction: column;
    }

    .package_section .box .detail-container .detail-box {
        margin: 35px 15px;
    }

    .client_section .box .detail-box {
        width: 100%;
    }

    .client_section .box .img-box {
        width: 250px;
    }

    .client_section::before {
        top: 34%;
        width: 450px;
        height: 282px;
    }

    .trip_section .box .form_container .range_input {
        flex-direction: column;
        align-items: stretch;
    }

    .info_section::before {
        transform: translate(-50%, 0) scale(3.3);

    }

    .info_section .info_container {
        width: 100%;
    }
}

@media (max-width: 576px) {
    .slider_section .box {
        flex-direction: column;
        text-align: center;
    }

    .service_section .box .detail-box {
        width: auto;
    }

    .slider_section .detail-box::before {
        left: 50%;
        transform: translateX(-50%);
    }

    .slider_section .detail-box {
        margin-bottom: 35px;
    }

    .package_section .box .detail-container .detail-box {
        border-radius: 75px;
        margin-left: 0;
        margin-right: 0;
    }

    .client_section .box .img-box {
        width: 175px;
    }

    .client_section::before {
        top: 35.5%;
        width: 300px;
        height: 200px;
    }
}

@media (max-width: 480px) {}

@media (max-width: 420px) {
    .client_section::before {

        top: 37%;

    }
}

@media (max-width: 365px) {
    .client_section::before {
        top: 35.5%;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }

}