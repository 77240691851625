@import url('https://fonts.googleapis.com/css2?family=Arizonia&amp;display=swap');

/* Root */
:root {
    ---pink: #f0dcd5;
    ---black: #222222;
    ---white: #ffffff;
    ---lightbrown: #4bdefb;
    ---darkbrown: #2b82c4;
    /* ---lightbrown:#a27427;
    ---darkbrown:#704309; */
    ---stoke: #ffe7de;
    ---lightpink: #f0dfdf;
    ---para: #646464;
}

/* Root */

/* Body */
* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
    text-decoration: none;

    box-sizing: border-box;
    font-family: 'Gilroy', sans-serif;
}

html {
    font-size: 100%;

}

body {
    background-color: #212121;
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

h4 {
    color: #2b82c4 !important;
}

section {
    padding: 20px, 150px;
}

/*body*/
/*header*/
.header {
    background-color: transparent;
    position: fixed;
    z-index: 999;
    width: 100%;
    margin-left: 0;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}

.header nav {
    margin-right: 0 !important;
    padding-right: 0 !important;
    display: flex;
}

.header .navbar3 {
    display: flex;
    align-items: center;
}

.header .navbar3 a {
    font-size: 16px;
    color: var(---black);
    font-weight: 700;
    padding: 15px 20px;
}

.header nav .right-data {
    display: flex;
    align-items: center;
    padding-right: 0;

}

.header nav .right-data #menu {
    color: var(---black);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.123);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    font-size: 20px;
    margin-right: 20px;
    display: none;
}

.header nav .right-data .button {
    background-color: var(---pink);
    width: 150px;
    height: 40px;
    margin-top: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    border-radius: 100px;
    box-shadow: 0px 5px 10px #fff;
}

.header nav .right-data .button span,
.navbar3 a {
    font-size: 16px;
    font-weight: 800;
    color: black;
}

.header nav .right-data .button span:hover {
    background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header .navbar3 a:hover {
    background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header nav .right-data #menu:hover {
    background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header .navbar3.active {
    visibility: inherit;
}

.heading {
    padding-top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    color: #f0dfdf;
}

.main-container {

    width: 100%;
    display: flex;

}

.m-container {
    min-height: 90vh !important;
    margin-bottom: 20px;
}

.post-jobs {
    width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.post-jobs .form {
    background-color: #fff;
    color: #1b1b1b;
    width: 100% !important;
    margin: 0 !important;
}

.form button {
    background-color: #2b82c4;
}

.editor {
    height: 400px;
}

.ql-container {
    min-height: 350px;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
}

.contact-us {
    width: 70%;
}

/* .cards:hover button {
    z-index: 999;
} */

.left-side {
    width: 15%;
}

.right-side {
    width: 15%;
}

.card3 {
    padding: 10px;
    width: 330px;
    height: 700px;
    margin: 10px;
    border-radius: 20px;
    background: #212121;
    box-shadow: 0 0 1px #f0dcd5,
    0 0 1px #272727;
    transition: 0.4s;
}

.blog {
    padding: 10px;
    width: 330px;
    height: 320px;
    margin: 10px;
    border-radius: 20px;
    background: #212121;
    box-shadow: 0 0 1px #f0dcd5,
        0 0 1px #272727;
    transition: 0.4s;
}

.hackathon {
    padding: 10px;
    width: 330px;
    height: 270px;
    margin: 10px;
    border-radius: 20px;
    background: #212121;
    box-shadow: 0 0 1px #f0dcd5,
        0 0 1px #272727;
    transition: 0.4s;
}

.card3:hover {
    translate: 0 -10px;
}

.card3-title {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin: 15px 0 0 10px;
}

.card3-image {
    height: 300px;
    background-color: #313131;
    border-radius: 15px;
    background: #313131;
    box-shadow: inset 5px 5px 3px #2f2f2f,
        inset -5px -5px 3px #333333;
}

.card3-image img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
}

.card3-body {
    position: relative;
    margin: 13px 0 0 10px;
    color: #fff;
    font-size: 15px;
}



.blog-title {
    height: 100px;
    color: black;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
}

.blog-title p {
    color: #212121;
}

h4 {
    color: #fff !important;
}

input,
button {
    cursor: pointer !important;
}

h4 span {
    color: #2b82c4;
}

.card3-btn {
    width: 140px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    transition: .2s cubic-bezier(0.19, 1, 0.22, 1);
    opacity: 0.6;
}

.card3-btn::after {
    content: '';
    border-bottom: 3px double rgb(214, 207, 113);
    width: 0;
    height: 100%;
    position: absolute;
    margin-top: -5px;
    top: 0;
    left: 5px;
    visibility: hidden;
    opacity: 1;
    transition: .2s linear;
}

.card3-btn .icon {
    transform: translateX(0%);
    transition: .2s linear;
    animation: attention 1.2s linear infinite;
}

.card3-btn:hover::after {
    visibility: visible;
    opacity: 0.7;
    width: 90%;
}

.card3-btn:hover {
    /* letter-spacing: 2px; */
    opacity: 1;
}

.card3-btn:hover .icon {
    transform: translateX(30%);
    animation: none;
}

@keyframes attention {
    0% {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(30%);
    }
}

/*footer*/
.footer {
    background-color: black;
    margin-bottom: 0;
    text-transform: uppercase;
}

.footer .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: var(---black); */
}

.footer .container .logo img {
    width: 100px;
    height: auto;
}

.footer .container .navbar3 a {
    font-size: 16px;
    color: var(---white);
    font-weight: 600;
    padding: 0px 20px;
}

.footer .container .search h3 {
    font-size: 20px;
    color: var(---white);
}

.footer .container .search .input {
    width: 300px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    margin-top: 20px;
}

.footer .container .search .input input {
    width: 100%;
    height: 50px;
    border-radius: 5px 0px 0px 5px;
    padding-left: 10px;
    font-size: 12px;
    color: var(---black);
    font-weight: 600;
}

.footer .container .search .input input::placeholder {
    font-size: 12px;
    color: var(---black);
    font-weight: 600;
}

.footer .container .search .input i {
    background: linear-gradient(90deg, var(---lightbrown), var(---darkbrown));
    width: 50px;
    height: 50px;
    color: var(---white);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}

/*footer*/











/* media quries */
@media (min-width: 1600px) {
    .card3,
    .blog,
    .hackathon {
        width: 400px;
    }

    .card3 {
        height: 600px!important;
    }

    .card3-image {
        height: 400px;
    }
}

@media (max-width:1536px) {
    section {
        padding: 10px 70px;
    }

}

@media (max-width:1280px) {
    section {
        padding: 10px 50px;
    }

    .header .container .navbar3 {
        position: absolute;
        background-color: var(---white);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.11);
        width: 100%;
        top: 10%;
        right: 0%;
        z-index: 1000;
        border-radius: 30px;
        visibility: hidden;
    }

    .header .container nav .navbar3 a {
        display: block;
        text-align: center;
    }

    .header .container nav .right-data #menu {
        display: inherit;
    }

    .footer .container {
        display: block;
    }

    .footer .container .logo {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer .container .navbar3 {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 10px 0px;
    }

    .footer .container .navbar3 nav a {
        display: block;
        line-height: 50px;
    }

    .footer .container .search {
        text-align: center;
    }

    .footer .container .search .input {
        margin: 10px auto;
    }
}

@media (max-width:1080px) {
    section {
        padding: 10px 25px;
    }

    .navbar3 {
        margin-top: 100px;
    }

}

@media (min-width:800px) {
    .fi-br-menu-burger {
        display: none;
    }

    .small {
        display: none;
    }
}

@media (max-width:800px) {
    .header nav {
        display: none;
    }

    .header .mobile-nav {
        width: 250px;
        height: 80vh;
        right: 0;
        top: 70px;
        padding: 10px;
        position: fixed;
        border-radius: 5px;
        z-index: 999;
        background-color: black;
    }

    .header .mobile-nav,
    .header .right-data .profile,
    .navbar3 {
        display: flex;
        flex-direction: column;
    }

    .header .right-data .button {
        background-color: var(---pink);
        width: 150px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        border-radius: 100px;
        box-shadow: 0px 5px 10px #fff;
    }

    .header .container .logo img {
        width: 100px;
    }

    .fi-br-menu-burger {
        position: fixed;
        z-index: 999;
        top: 10px;
        right: 10px;
    }

    section {
        padding: 10px 16px;
    }

    .post-jobs {
        width: 100%;

    }

    .post-jobs .form {
        width: 100% !important;
        margin: 0 !important;
        height: fit-content;
    }

    .editor,
    .quill {
        height: 400px;
    }

    .ql-container {
        height: 300px !important;
        margin-bottom: 100px;
    }

    .main-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .cards {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .left-side {
        width: 15%;
        display: none;
    }

    .right-side {
        display: none;
        width: 15%;
    }

    .contact-us {
        width: 100%;
    }

    .card3,
    .blog,
    .hackathon {
        width: 100%;
    }

    .blog {
        display: flex;
        flex-direction: column;
    }

    .card3-image img {
        width: 100%;
    }

    .footer .container .logo img {
        max-width: 100px;
    }

    .profile-input {
        width: 50%;
    }

    .profile-button {
        width: 100%;
    }

    .profile-button button {
        width: 100% !important;
    }


}

@media (max-width:640px) {
    section {
        padding: 10px 10px;
    }

    .header .container nav .right-data #menu {
        margin-right: 0px;
    }

    .header .container nav .right-data button {
        display: none;
    }

}

@media (max-width:500px) {
    .header .container .logo {
        width: 100%;
    }

    .header .container .logo img {
        width: 100px;
    }

    .header .container nav .right-data #menu {
        width: 60px;
        height: 60px;
        font-size: 16px;
    }

    .header .container .navbar {
        top: 9%;
    }

}

@media (max-width:400px) {

    .footer {
        margin-top: 50px;
    }

    .footer .container .search .input {
        width: 100%;
    }

    .footer .container .logo {
        width: 80%;
        margin: 10px auto;
    }

    .footer .container .logo img {
        width: 100%;
    }
}

/* media quries */