
.team{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.team-member{
  width: 200px;
  margin: 10px;
}
.contact-detail{
display: flex;
flex-direction: column;
}
.contact-detail .fa-square-whatsapp{
  font-size: 50px;
  color: green;
}
.profile-menu{
  width: 200px;
  right: 0;
  top: 70px;
  padding: 10px;
  position: fixed;
  border-radius: 5px;
  z-index: 999;
  background-color: black;
}
.profile-menu{
  display: flex;
  flex-direction: column;
}

.profile-menu button{
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
/* .profile-menu{
  position: fixed;
  z-index: 999;
  display: flex;
  flex-direction: column;
} */
.form{
    width: 400px;
 border-radius: 5px; 
 padding: 20px;  
}

.register {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
}

/* MoreDetailsContainer.css */

.more-details-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  color: #fff!important;
}

.more-details-container h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.more-details-container img {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.more-details-container p {
  margin-bottom: 15px;
}

/* About.css */

.about {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  margin: auto;
  color: #fff;
}
@media (max-width:800px) {
  .about {
    width: 100%;
    margin: 10px;
  }
}
.about-content,
.about-section {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.about h2,
.about-section h3 {
  font-size: 28px;
  margin-bottom: 20px;
}

.about p,
.about-section p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
  
}

.jobs {
  background-color: #f0f8ff; /* Light Blue Background for Jobs Section */
  color: #000;
}

.hackathons {
  color: #000;
  background-color: #2b82c4; /* Khaki Background for Hackathons Section */
}

.news-blogs {
  color: #000;
  background-color: #e0ffff; /* Light Cyan Background for News & Blogs Section */
}

/* ContactUs.css */

.contact-us {
  padding: 20px;
}

.contact-form {
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.contact-form p {
  font-size: 16px;
  margin-bottom: 20px;
}

.contact-form form {
  display: grid;
  gap: 15px;
}

.contact-form label {
  font-size: 16px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form button {
  background-color: #2b82c4;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: #4bdefb;
}

.social-links {
  margin-top: 30px;
}

.social-links h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

.social-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.social-links li {
  margin-bottom: 10px;
}

.social-links a {
  text-decoration: none;
  color: #007bff;
  font-size: 16px;
}

.social-links a:hover {
  text-decoration: underline;
}

.loadingSpinnerContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loadingSpinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #6d6d6d transparent #fff transparent;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  